/* eslint-disable consistent-return */
import ls from "@/plugins/secureLs";
import io from "socket.io-client";
import dayjs from "dayjs";
import Repository from "../../../../services/repositories/RepositoryFactory";
import { errorMessage } from "../../../../utils/ErrorMessages";

const tender = Repository.service("load");

export default {
  namespaced: true,
  state: {
    requestInProcess: false,
    stops: {
      stopsPickup: null,
      stopsDelivery: null
    },
    chosenPickupDate: null,
    tenderSocket: null,
    responseTenderSocket: null,
    isLocalTimeAlert: false
  },
  getters: {
    getRequestInProcess: (state) => state.requestInProcess,
    getStop: (state) => (stopType) => state.stops[stopType],
    getChosehPickupDate: (state) => state.chosenPickupDate,
    getTenderSocket(state) {
      return state.responseTenderSocket;
    },
    getLocalTimeAlert(state) {
      return state.isLocalTimeAlert;
    }
  },
  mutations: {
    setRequestInProcess: (state, payload) => {
      state.requestInProcess = payload;
    },
    setStopObject(state, payload) {
      state.stops[payload.stopType] = payload.body;
    },
    setChosenPickupDate(state, payload) {
      const formattedDate = dayjs(payload).format("MM-DD-YYYY");
      state.chosenPickupDate = formattedDate;
    },
    setSocketResponse(state, payload) {
      state.responseTenderSocket = payload;
    },
    resetSocketResponse(state) {
      state.responseTenderSocket = [];
    },
    setTenderSocket(state, payload) {
      state.tenderSocket = io(payload.route, {
        path: "/sio/socket.io/",
        transports: ["websocket"],
        forceNew: true,
        query: {
          Authorization: `Bearer ${ls.get("portal-front-token")}`,
          room_id: payload.loadId,
        },
      });
    },
    disconnectSocket(state) {
      if (state.tenderSocket) {
        state.tenderSocket.disconnect();
      }
    },
    setisLocalTimeAlert(state, payload) {
      state.isLocalTimeAlert = payload;
    }
  },
  actions: {
    async createTender({ commit, rootGetters }, payload) {
      try {
        const response = await tender.createTender(payload, rootGetters.getAbortController);
        commit("setCancelRequest", null, { root: true });
        return response;
      } catch (error) {
        if (error.response) {
          return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
      }
    },
    async uploadFile({ rootGetters }, data) {
      try {
        const response = await tender.uploadFile(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    joinToTenderSocket({ commit, dispatch }, id) {
      const loadId = id;
      const route = `${process.env.VUE_APP_BACK_ROUTE}/notifications`;
      const payload = {
        route,
        loadId,
      };
      commit("setTenderSocket", payload);
      dispatch("tenderSocketListener");
    },
    tenderSocketListener({ state, commit }) {
      state.tenderSocket.on("portal-tender-terminated", (data) => {
        commit("setSocketResponse", { notification: "portal-tender-terminated", data });
      });
      state.tenderSocket.on("portal-magaya-terminated", (data) => {
        commit("setSocketResponse", { notification: "portal-magaya-terminated", data });
      });
    }
  },
};
